import { defineStore } from 'pinia'

export const useProductCategoryStore = defineStore('ProductCategory', {
  state: () => ({
    data: [] as ProductCategory[],
    loading: true,
  }),
  getters: {
    // find category recursively
    findById:
      (state) =>
      (id: number): ProductCategory | null => {
        const find = (category: ProductCategory): ProductCategory | null => {
          if (category.id === id) return category
          if (!category.children) return null
          for (const child of category.children) {
            const found = find(child)
            if (found) return found
          }
          return null
        }
        for (const category of state.data) {
          const found = find(category)
          if (found) return found
        }
        return null
      },
    listSellCategories: (state) => {
      return state.data.filter((c) => c.is_sell)
    },
    listRentCategories: (state) => {
      // the order is custom as asked by Masa team
      // also contains hardcoded image path
      const rentCategoriesHardcoded = [
        {
          id: 21,
          name: 'Scaffolding',
          icon_url: '/images/home/sewa/scaffolding-gold.png',
        },
        {
          id: 234,
          name: 'Scaffolding Galvanis',
          icon_url: '/images/home/sewa/scaffolding-silver.png',
        },
        {
          id: 232,
          name: 'Caster Wheel',
          icon_url: '/images/home/sewa/caster-wheel.png',
        },
        {
          id: 23,
          name: 'Tubular System',
          icon_url: '/images/home/sewa/tubular-system.png',
        },
        {
          id: 233,
          name: 'Clamp',
          icon_url: '/images/home/sewa/clamp.png',
        },
        {
          id: 22,
          name: 'Bekisting System',
          icon_url: '/images/home/sewa/bekisting-system.png',
        },
        {
          id: 230,
          name: 'Ringlock System',
          icon_url: '/images/home/sewa/ringlock-system.png',
        },
        {
          id: 231,
          name: 'Kwikstage System',
          icon_url: '/images/home/sewa/kwikstage-system.png',
        },
      ]

      // this list came from WP API
      const rentCategoriesFromApi = state.data.filter((c) => c.is_rent)

      // return merged data from hardcode and WP API
      // filter the false to ensure the category exist in wordpress
      return rentCategoriesHardcoded.map((hardcoded) => {
        const find = rentCategoriesFromApi.find((fromApi) => fromApi.id === hardcoded.id)
        return {
          product_count: 0, // to pass typescript check in component filter_product_by_category
          children: [], // to pass typescript check in component filter_product_by_category
          slug: '', // to pass typescript check in component filter_product_by_category
          parent_id: null, // to pass typescript check in component filter_product_by_category
          is_rent: true, // to pass typescript check in component filter_product_by_category
          is_sell: false, // to pass typescript check in component filter_product_by_category
          image_url: '', // to pass typescript check in component filter_product_by_category
          description: null, // to pass typescript check in component filter_product_by_category
          brands: [], // to pass typescript check in component filter_product_by_category
          ...hardcoded,
          ...(find ?? {}),
        }
      })
    },
  },
  actions: {
    setData(data: ProductCategory[]) {
      this.data = data
    },
    setLoadingDone() {
      this.loading = false
    },
  },
})
